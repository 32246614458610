@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
.master-text{
    font-size: 31px;
    font-family: 'Staatliches', cursive;
    font-weight: 100;
}
.nav-text{
    font-size: 16px;
    font-family:'Staatliches', cursive;
}
