@import url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap');

header{
  margin:0;
  padding:0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


/* Info Panel */
.container{
  background-color:whitesmoke;
}
.info-panel{
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-top: 0px;
  background-color:whitesmoke;
  padding: 10px;
  position: relative;
}
.info-panel img{
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.info-panel h4{
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
}

.info-panel p{
  font-size: 10px;
  color: #ACACAC;
  margin-top: 5px;
  text-align:justify;
}
/* Akhir Info Panel */

/* Workingspace */
 .workingspace{
   margin-left: 20px;
     margin-top: 50px;
     text-align: center;
     margin: 100px 0;
 }
 .workingspace h3{
     font-size: 30px;
     font-weight: 300;
     text-transform: uppercase;
     margin-top: 50px
 }
 .workingspace h3 span{
     font-weight: 500;
 }
 .workingspace p{
     font-size: #ACACAC;
     font-weight: 200;
     margin: 25px 0;
 }
