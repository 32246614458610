@import url(https://fonts.googleapis.com/css?family=Roboto:700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



header{
  margin:0;
  padding:0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


/* Info Panel */
.container{
  background-color:whitesmoke;
}
.info-panel{
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-top: 0px;
  background-color:whitesmoke;
  padding: 10px;
  position: relative;
}
.info-panel img{
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.info-panel h4{
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
}

.info-panel p{
  font-size: 10px;
  color: #ACACAC;
  margin-top: 5px;
  text-align:justify;
}
/* Akhir Info Panel */

/* Workingspace */
 .workingspace{
   margin-left: 20px;
     margin-top: 50px;
     text-align: center;
     margin: 100px 0;
 }
 .workingspace h3{
     font-size: 30px;
     font-weight: 300;
     text-transform: uppercase;
     margin-top: 50px
 }
 .workingspace h3 span{
     font-weight: 500;
 }
 .workingspace p{
     font-size: #ACACAC;
     font-weight: 200;
     margin: 25px 0;
 }

.MDBContainer .Card-Image{
    height: 300px;
}


.master-text{
    font-size: 31px;
    font-family: 'Staatliches', cursive;
    font-weight: 100;
}
.nav-text{
    font-size: 16px;
    font-family:'Staatliches', cursive;
}


